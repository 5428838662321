.table {
  width: 100%; /* Ocupará el 100% del contenedor padre */
  max-width: 100%; /* No excede el ancho del contenedor */
  min-width: 320px; /* Establece un ancho mínimo razonable para pantallas pequeñas */
  border-collapse: collapse; /* Asegura que los bordes no colapsen */
  border-spacing: 0; /* Elimina el espacio entre celdas */
  background-color: var(--background-light);
  color: var(--primary-text);
  font-size: 14px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
  border-radius: 16px;
  box-shadow:
    inset 0px 1px 4px rgba(255, 255, 255, 0.1),
    /* Resplandor interior */ 0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */
}

.table th {
  text-align: left;
  padding: 12px;
  background-color: var(--background-dark);
  color: var(--primary-text);
  font-weight: bold;
  border-bottom: 2px solid var(--tertiary-text);
  text-align: center;
}

.table tr:last-child td {
  border-bottom: none; /* Elimina el borde inferior de la última fila */
}

.table th:first-child {
  border-top-left-radius: 16px; /* Redondea la esquina superior izquierda */
}

.table th:last-child {
  border-top-right-radius: 16px; /* Redondea la esquina superior derecha */
}

.table tr:last-child td:first-child {
  border-bottom-left-radius: 16px; /* Redondea la esquina inferior izquierda */
}

.table tr:last-child td:last-child {
  border-bottom-right-radius: 16px; /* Redondea la esquina inferior derecha */
}

.table td {
  padding: 12px;
  border-bottom: 1px solid var(--tertiary-text);
  text-align: center;
}

.success {
  color: var(--success); /* Ejemplo: verde */
}

.pending {
  color: var(--primary); /* Ejemplo: amarillo */
}

.error {
  color: var(--error); /* Ejemplo: rojo */
}

.canceled {
  color: var(--light-grey);
}

.tableRow {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tableRow:hover {
  background: var(--grey-gradient);
}

.footer {
  padding: 6px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 14px;
  margin-top: 8px;
  background: linear-gradient(145deg, #121212, #1c1c1c);
  /* Efecto de borde luminoso */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
  align-self: center;
  box-shadow:
    inset 0px 1px 4px rgba(255, 255, 255, 0.1),
    /* Resplandor interior */ 0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */
}

.showMoreTickets {
  cursor: pointer;
  color: var(--primary);
}

.showMoreTickets:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .table th,
  .table td {
    padding: 6px;
    font-size: 14px; /* Reduce el tamaño de la fuente */
  }

  .table {
    border: 1px solid rgba(255, 255, 255, 0.3); /* Línea blanca tenue */
  }
}
