.card {
  align-items: flex-start;
  user-select: none;
}

.type {
  color: var(--tertiary-text);
  margin-bottom: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.containerPack {
  align-items: center;
  width: -webkit-fill-available;
}
.creditsContainer {
  align-items: center;
}
.credits {
  align-items: center;
  font-weight: bold;
  font-size: 30px;
  margin: 24px;
  text-align: center;
  i {
    margin-bottom: 10px;
    font-size: 40px;
    color: var(--primary);
  }
}
.prices {
  margin: 32px 0px;
  font-size: 20px;
}
.realPrice {
  text-decoration: line-through;
  color: rgba(255, 255, 255, 0.5);
  text-decoration-thickness: 0.57px; /* Hace la línea más delgada */
  font-size: 16px;
  text-align: center;
}
.price {
  font-weight: 600;
}
.button {
  width: -webkit-fill-available;
}

.divider {
  margin: 24px 0px;
}

.list {
  color: var(--tertiary-text);
  i {
    color: var(--primary);
  }
}

.pricesMobile {
  display: none;
}

@media (max-width: 768px) {
  .card {
    width: -webkit-fill-available;
  }
  .header {
    justify-content: space-between;
    width: -webkit-fill-available;
    align-items: center;
    margin-bottom: 16px;
  }
  .type {
    margin-bottom: 0px;
  }
  .pricesMobile {
    display: flex;
  }
  .prices {
    display: none;
  }
  .creditsContainer {
    flex-direction: row;
    width: -webkit-fill-available;
    justify-content: space-between;
    align-items: center;
  }

  .credits {
    font-size: 24px;
    margin: 0px;
    margin-bottom: 16px;
    i {
      margin-bottom: 10px;
    }
  }
  .button {
    font-size: 16px;
    height: 36px;
  }

  .divider {
    margin: 12px 0px;
  }

  .list {
    font-size: 12px;
    i {
      font-size: 12px;
    }
  }
}
