.button {
  padding: 8px 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 16px;
  background: var(--primary-gradient);
  font-size: 16px;
  font-weight: bold;
  color: var(--dark);
  cursor: pointer;
  box-sizing: border-box; /* Asegura que el padding y border no alteren el tamaño */
  transition: all 0.3s ease; /* Transiciones suaves */
  border: 1px solid var(--dark);
  user-select: none;
}

.button:hover {
  background: linear-gradient(145deg, #ffd700, #ffcc00, #ff9900, #ffbf00);
  transform: scale(1.01); /* Efecto de ligera ampliación */
  transition: all 0.3s ease; /* Transición suave */
}

.primary {
  background: linear-gradient(145deg, #f9d423, #f6a623, #e6ac00, #d4af37);
}

.secondary {
  background: var(--white-gradient);
}

.secondary:hover {
  background: var(--white-gradient);
  opacity: 0.8;
}

.disabled {
  background: transparent;
  cursor: not-allowed;
  border: 1px solid var(--tertiary-text);
  color: var(--tertiary-text);
}
.disabled:hover {
  background: transparent;
  box-shadow: none;
}

.small {
  padding: 4px 12px !important;
  font-size: 12px;
  height: min-content;
}

.large {
  padding: 12px 24px !important;
  font-size: 20px;
}
