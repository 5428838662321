.content {
  padding: 26px;
  border-radius: 16px;
  margin: -20px -24px;
  border: 1px solid silver;
  z-index: 1;
  position: relative;
  overflow: hidden;
  background: var(--dark-gradient);
  min-width: 400px;
  color: var(--primary-text) !important;
}

.header {
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 26px;
}

.titleGolden {
  color: var(--dark);
}

.closeIcon {
  cursor: pointer;
}
.closeIcon:hover {
  color: var(--primary);
}

.buttons {
  margin-top: 26px;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .modal {
    top: 16px;
    z-index: 99999;
  }
  .content {
    min-width: auto;
    padding: 16px;
    z-index: 99999;
  }

  .header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .buttons {
    margin-top: 16px;
  }
}
