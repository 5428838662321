.tableContainer {
  margin: 40px 0px;
  overflow-x: auto; /* Para scroll horizontal si la tabla es más ancha que el contenedor */

  display: block; /* Evita que Flexbox lo restrinja */
  white-space: nowrap; /* Evita que el contenido de la tabla se rompa */
  max-width: 100%; /* Evita que el contenedor se expanda más allá del ancho */

  width: 100%;
  /* Efecto de borde luminoso */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
  border-radius: 8px;
  box-shadow:
    inset 0px 1px 4px rgba(255, 255, 255, 0.1),
    /* Resplandor interior */ 0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */
}

.table {
  width: 100%;
  min-width: 700px; /* Ajusta según sea necesario */
  border-collapse: collapse;
  background-color: var(--background-light);
  color: var(--primary-text);
  font-size: 14px;
}

.table th {
  text-align: left;
  padding: 12px;
  background-color: var(--background-dark);
  color: var(--primary-text);
  font-weight: bold;
  border-bottom: 2px solid var(--tertiary-text);
  text-align: center;
}

.table tr:last-child td {
  border-bottom: none; /* Elimina el borde inferior de la última fila */
}

.table td {
  padding: 12px;
  border-bottom: 1px solid var(--tertiary-text);
  text-align: center;
}

.table tr:hover {
  background-color: var(--hover-background);
}

.success {
  color: var(--success); /* Ejemplo: verde */
}

.pending {
  color: var(--primary); /* Ejemplo: amarillo */
}

.error {
  color: var(--error); /* Ejemplo: rojo */
}

.canceled {
  color: var(--light-grey);
}

.tableRow {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tableRow:hover {
  background: var(--grey-gradient);
}

.approved {
  color: var(--success);
}

@media (max-width: 768px) {
  .tableContainer {
    overflow-x: auto;
    margin: 20px 0px;
  }

  .table {
    min-width: 600px !important; /* Previene que la tabla se achique demasiado */
  }

  .table th,
  .table td {
    padding: 8px; /* Reduce padding para mejorar visibilidad */
    font-size: 12px; /* Reduce tamaño en móviles */
  }
}
