.container {
}
.title {
  color: var(--primary-text);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

.description {
  color: var(--primary-text);
  font-size: 16px;
  font-size: 500;
  text-align: center;
}

.packs {
  gap: 20px;
  justify-content: space-around;
  width: -webkit-fill-available;
  margin-top: 40px;
}

.divider {
  margin: 50px 0px;
}

.customPack {
  margin-bottom: 100px;
}

.inputContainer {
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: -webkit-fill-available;
  margin-bottom: 16px;
}

.input {
  max-width: 400px;
  height: 50px;
  margin-right: 12px;
}

.button {
  height: 50px;
}

.note {
  color: var(--tertiary-text);
  text-align: center;
  font-size: 14px;
}

.cost {
  color: var(--primary);
  text-align: center;
  font-size: 16px;

  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .title {
    font-size: 18px;
  }

  .description {
    font-size: 14px;
  }

  .packs {
    flex-direction: column;
  }

  .button {
    white-space: nowrap;
    font-size: 12px;
  }
}
