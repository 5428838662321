.container {
  min-width: 800px;
}

.containerPagination {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.modal {
  top: 16px;
}

@media (max-width: 768px) {
  .container {
    min-width: auto;
  }
}
