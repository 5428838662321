.container {
  position: relative;
  min-height: 100vh;
}

.containerFixtureSection {
  height: 100vh;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  position: absolute;
  align-items: center;
  z-index: 1;
}

.containerFix {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  align-items: center;
  background: linear-gradient(104deg, #012b07, #012008, #042a01, #052101);
  padding-bottom: 64px;
}

.backgroundImg {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: -115px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.backgroundImg::after {
  content: '';
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: -115px;
  left: 0;
  right: 0;
  bottom: 0;
}

.mobileBackgroundImg {
  display: none;
}

.content {
  overflow: hidden;
  padding: 24px;

  background-color: rgba(0, 0, 0, 0.5);

  position: relative;
  z-index: 1;
}

.goldenContent {
  background: linear-gradient(
    145deg,
    #e6ac00,
    #c89200,
    #b57f1b,
    #99641c,
    #d4af37
  );
  color: var(--dark);
  border: 1px solid var(--dark);
  box-shadow: 0px 5px 10px 0px rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  transition: all 1s ease; /* Transiciones suaves */
}

.goldenContent::after {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3)
  );

  transform: skewX(-30deg);
  animation: shine 30s infinite; /* Ciclo completo de 10s */
  pointer-events: none; /* No afecta interacciones */
}

.buttonConfirm {
  margin-top: 8px;
  height: 50px;
}

.buttonConfirmGolden {
  background: rgba(0, 0, 0, 0.9);
  color: var(--primary-text);
}
.buttonConfirmGolden:hover {
  border: 1px solid var(--dark);
  background: rgba(0, 0, 0, 0.8);
  transform: scale(0.99); /* Efecto de ligera ampliación */
}

.disabled {
  opacity: 0.5;
}
.disabled:hover {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .backgroundImg {
    display: none;
  }

  .mobileBackgroundImg {
    display: block;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: -115px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .mobileBackgroundImg::after {
    content: '';
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: -115px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .content {
    width: -webkit-fill-available;
    flex: 1;
    padding: 0px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  .containerFix {
    padding: 0 8px 32px 8px;
  }
  .containerFixtures {
    padding: 0px 0px 16px 0px;
    width: -webkit-fill-available;
  }
  .buttonConfirm {
    padding: 16px;
    font-size: var(--m);
    line-height: var(--m);
    margin: 16px 0px;
  }

  .prizeContainer {
    gap: 6px;
    padding: 8px;
  }
  .prizeText {
    font-size: var(--m);
  }
  .prizeAmount {
    font-size: var(--l);
  }
}

@keyframes shine {
  0% {
    transform: translateX(-200%) skewX(-30deg); /* Inicio del efecto */
    opacity: 1; /* Brillo visible */
  }
  30% {
    transform: translateX(200%) skewX(-30deg); /* Fin del efecto */
    opacity: 1; /* Brillo visible */
  }
  31% {
    opacity: 0; /* Desaparece completamente */
  }
  100% {
    opacity: 0; /* Mantener invisible hasta el siguiente ciclo */
  }
}
