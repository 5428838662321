.title {
  color: var(--primary-text);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

.description {
  color: var(--primary-text);
  font-size: 16px;
  font-size: 500;
  text-align: center;
}

.container {
  padding-bottom: 80px;
}

@media (max-width: 768px) {
  .container {
    width: -webkit-fill-available;
  }

  .title {
    font-size: 18px;
  }

  .description {
    font-size: 14px;
  }
}
