.buttons {
  justify-content: space-between;
  align-items: center;

  border-radius: 6px;
  position: relative;
  height: 50px;
  user-select: none;
}

.button {
  min-width: 150px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  transition:
    background-color 0.3s,
    color 0.3s;
}
.button:hover {
  opacity: 0.8;
}
.buttonActive {
  color: var(--dark);
  background: var(--primary-gradient);
  border-color: var(--dark);
}

.buttonLeft {
  border-radius: 6px 0px 0px 6px;
  border: 1px solid var(--tertiary-text);
  padding: 16px;
}
.buttonRight {
  border-radius: 0px 6px 6px 0px;
  border: 1px solid var(--tertiary-text);
  padding: 16px;
}

.buttonMiddle {
  border-radius: 0px;
  border: 1px solid var(--tertiary-text);
  padding: 16px;
}

@media (max-width: 768px) {
  .button {
    min-width: 100px;
  }
  .buttonLeft {
    padding: 8px;
  }
  .buttonRight {
    padding: 8px;
  }

  .buttonMiddle {
    padding: 8px;
  }
}
