.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 40px;
}

.title {
  color: var(--tertiary-text);
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.description {
  color: var(--tertiary-text);
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
}
.card {
  i {
    color: var(--primary);
    font-size: 56px;
  }
  min-width: 500px;
}
.credits {
  align-items: center;
  gap: 12px;
  margin-bottom: 26px;
}

.divider {
  margin: 50px;
}
@media (max-width: 768px) {
  .container {
    padding: 16px;
  }
  .card {
    i {
      color: var(--primary);
      font-size: 36px;
    }
    min-width: 0;
  }

  .divider {
    margin: 16px;
  }
}
