.buttonsGroup {
  justify-content: flex-end;
}

.buttonGroup {
  padding: 6px 16px;
}

@media (max-width: 768px) {
  .buttonsGroup {
    justify-content: center;
  }
  .buttonGroup {
    padding: 6px 8px;
    font-size: 14px;
  }
}
