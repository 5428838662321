.container {
  justify-content: center;
  width: -webkit-fill-available;
  padding: 80px;
  align-items: center;
}
.form {
  display: flex;
  flex-direction: column;
  width: 600px;
}

.fullname {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.createPasswordText {
  color: var(--primary-text);
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin-top: 40px;
}

.createPassword {
  width: 600px;
}

.button {
  padding: 12px;
  margin-top: 26px;
}

@media (max-width: 768px) {
  .container {
    min-height: 300px;
    padding: 20px;
  }
  .form {
    width: -webkit-fill-available;
  }
}
