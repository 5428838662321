.modal {
}
.contentClassName {
  background: linear-gradient(145deg, #000000, #2c2c2c, #212121, #000000);
}

.contentGolden {
  background: linear-gradient(
    145deg,
    #e6ac00,
    #c89200,
    #b57f1b,
    #99641c,
    #d4af37
  );
  color: var(--dark);
  border: 1px solid var(--dark);
  box-shadow: 0px 5px 10px 0px rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  transition: all 1s ease; /* Transiciones suaves */
}

.contentGolden::after {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3)
  );

  transform: skewX(-30deg);
  animation: shine 30s infinite; /* Ciclo completo de 10s */
  pointer-events: none; /* No afecta interacciones */
}

.totalPoints {
  background-color: rgba(0, 0, 0, 0.7);
  /* Efecto de borde luminoso */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Línea blanca tenue */
  box-shadow:
    inset 0px 1px 4px rgba(255, 255, 255, 0.1),
    0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */
  border-radius: 16px;
  color: var(--primary-text);
  font-weight: 600;
  font-size: 20px;
  gap: 16px;
  padding: 6px 24px;
  align-items: center;
  justify-content: flex-end;

  width: min-content;
  align-self: flex-end;
  margin-top: 8px;
}

.containerPoints {
  border-radius: 8px;

  width: 40px;
  color: var(--primary-text);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  font-weight: bold;

  /* Efecto de borde luminoso */
  border: 2px solid rgba(255, 255, 255, 0.5); /* Línea blanca tenue */
  box-shadow:
    inset 0px 1px 4px rgba(255, 255, 255, 0.1),
    0px 4px 10px rgba(0, 0, 0, 0.8); /* Sombra exterior sutil */
}

.note {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  margin-top: 12px;

  border-top: 1px solid var(--mid-grey);
  padding-top: 6px;
}

@media (max-width: 768px) {
  .content {
    padding: 0px;
  }
  .modal {
    top: 0;
  }
}

@keyframes shine {
  0% {
    transform: translateX(-200%) skewX(-30deg); /* Inicio del efecto */
    opacity: 1; /* Brillo visible */
  }
  30% {
    transform: translateX(200%) skewX(-30deg); /* Fin del efecto */
    opacity: 1; /* Brillo visible */
  }
  31% {
    opacity: 0; /* Desaparece completamente */
  }
  100% {
    opacity: 0; /* Mantener invisible hasta el siguiente ciclo */
  }
}
