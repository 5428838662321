.whatsapp {
  position: relative;
}

.link {
  color: #2a81a7;
  position: fixed;
  bottom: 22rem;
  right: 19rem;
  z-index: 99999;
  font-size: var(--s);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue',
    Helvetica, Arial, sans-serif;
  cursor: pointer;
  opacity: 0.9;
  text-decoration: underline;
}

.link:hover {
  opacity: 1;
  text-decoration: underline;
  color: #34b7f1;
}
.socialGroup {
  left: 24px;
  bottom: 40%;
}

@media (max-width: 768px) {
  .link {
    bottom: 22rem;
    right: 16.5rem;
  }
  .socialGroup {
    display: none;
  }
}
